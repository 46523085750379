<template>
    <div>
      <h1>Launching Application...</h1>
      <p>Please wait while we process your session.</p>
    </div>
  </template>
  
<script setup>
  import { onMounted  } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from "vuex";

  const router = useRouter();
  const store = useStore();
  const urlParams = new URLSearchParams(window.location.search);
  const launch = urlParams.get('launch');
  const iss = urlParams.get('iss');
  // const token = localStorage.getItem("token");
  // const deepLinkParams = ref({});

  // const sfhirToken = ref('');
  // const ehrPatientId = ref('');
  // const ehrOrgName = ref('');
onMounted(() => {
  // if (launch && iss) {
  //   deepLinkParams.value = { launch: launch, iss: iss };
  //   localStorage.setItem('deepLinkParams', JSON.stringify(deepLinkParams.value));
  // }
  // if(token){
  sendToBackend();
  // }else{
  //   router.push('/login')
  // }
});

// eslint-disable-next-line no-unused-vars
const sendToBackend = async () => {
      const response = await fetch('https://ai-stage.ensofia.app:8443/fast/ehr_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ launch, iss }),

      });
      console.log(JSON.stringify({ launch, iss }))
      const responseData = await response.json();
      const ehrPatientId =responseData.patientId;
      const ehrOrgName= responseData.organizationName;
      const ehrAccessToken= responseData.accessToken;
      const ehrEncounterId =responseData.encounter;
      const ehrDoctorName = responseData.practitioner.first_name;
      const token = responseData.ensofia_token.token;
      const refresh_token = responseData.ensofia_token.refresh_token;
      // const ehrPatientId ='erXuFYUfucBZaryVksYEcMg3';
      // const ehrOrgName= 'Epic Integration';
      // const ehrAccessToken= 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ1cm46b2lkOjEuMi44NDAuMTE0MzUwLjEuMTMuMC4xLjcuMy42ODg4ODQuMTAwIiwiY2xpZW50X2lkIjoiMWZmYTQ0OTUtYzM0OS00MWI4LTk5Y2QtNzRmOTAyZjk3ZDQ5IiwiZXBpYy5lY2kiOiJ1cm46ZXBpYzpPcGVuLkVwaWMtY3VycmVudCIsImVwaWMubWV0YWRhdGEiOiJkOG40YUF6TDN3bGV6NUQwVGFuMURKbGZWUlE5OWVlYlRrZFhwMVY5OS1mUkFSRlh4YjVCYTZhdUMxT0poeEdYQnUxV1FVa0ZUVGxnUnFpVG45VGtNWjItbFctVXZ5MEx4NE5kLWRIeGo0MC1DS0llSWpEMjJmekNtczg4Sk5IOCIsImVwaWMudG9rZW50eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM1Njc2MjgzLCJpYXQiOjE3MzU2NzI2ODMsImlzcyI6InVybjpvaWQ6MS4yLjg0MC4xMTQzNTAuMS4xMy4wLjEuNy4zLjY4ODg4NC4xMDAiLCJqdGkiOiIxNzI5ZjJlMS05NmRlLTQ2ZWMtOTA3OS01MDQxM2QzNTY0NTciLCJuYmYiOjE3MzU2NzI2ODMsInN1YiI6ImUzVjZFSEk2Y2pxMGpQeFZKN0dKT0htbzlqTzlvaElGdVFpcnF6VGdHMEFJMyJ9.LEuTSUud63VgqskZHacp20isQkysMUB9YftoYXqVKKJcQmn0IP3Gscu-jI0ttMBwqU3QM2QZS-Gbxf54C3tIJadA4LrhjvpviJOXMCWjm19RxSNehA7d37OLA-9Wnc_SFjEbKJ6jcKlHA4COSj9m0bjczQEBdZyJaCGjyzSTF4HYG3dtVQ9SA0P1caVrWepdZST4fcR4DtbnhZr2ChcxgbyAiKfYwpo_dXqMdIE7bPI8KscsJBe_CAvlBgHgzTvOV2P-eo_khBCtGBDQlqY7ID4JuzJJbLNKX0yuu2RH0B3msSGOqFtdGrg66IYdmRYAJ4y6rJ8EQuWKtMD1xvtmDg';
      // const ehrEncounterId ='eGmO0h.1.UQQrExl4bfM7OQ3';
      // const ehrDoctorName = 'Provider';
      // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwdWJsaWNfaWQiOiIyYTljNzU4Zi03MTM0LTRmMTgtYTY3Yi0yODliMDU3ZDFjZDUiLCJ0eXBlIjoidG9rZW4iLCJleHAiOjE3MzU2NzYyODN9.vkV8Io5LZ3EKkZ24bmRC0lhLazkcJOcfSNVzNNGle2w';
      // const refresh_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwdWJsaWNfaWQiOiIyYTljNzU4Zi03MTM0LTRmMTgtYTY3Yi0yODliMDU3ZDFjZDUiLCJ0eXBlIjoicmVmcmVzaCIsImV4cCI6MTczNTY3OTg4M30.9Utt16O4semqktv4UegFW_OV2ZzglhwSXD_WqqN0cpI';
      console.log("token before",localStorage.getItem('token'))
      if( !localStorage.getItem('token')){
        console.log("ensofia token",token)
        localStorage.setItem('token',token);
        localStorage.setItem('refreshToken',refresh_token);
        store.commit('auth/SET_AUTHENTICATED', true);
        console.log("ensofia token logged in")
      }
      console.log('Backend response:', responseData);
      // const ehrPatientId = responseData.patientId;
      // const ehrOrgName = ref('');
      if (!response.ok) {
      //   // sfhirLogin(ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName,ehrAccessToken);
        throw new Error('Failed to send data to the backend.');
      }
      else{
        sfhirLogin(ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName,ehrAccessToken);
      }

 
  };
  const sfhirLogin = async (ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName,ehrAccessToken) => {
    console.log("sfhirLogin")
    const basicAuth = btoa(`${'integrator@ensofia.com'}:${'AnalYtics$2420'}`);
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}sfhir_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${basicAuth}`,
        },
      });
      const responseData = await response.json();
      const sfhirToken = responseData.access_token;
      // console.log(responseData)
      if (!response.ok) {
        // throw new Error('Failed to send data to the backend.');
      }
      else{
        getPatientData(sfhirToken,ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName,ehrAccessToken);
      }
      
  }
  const getPatientData = async (sfhirToken,ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName,ehrAccessToken) => {
    console.log("tokens",sfhirToken)
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}patient_ehr_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sfhirToken}`,
        },
        body: JSON.stringify({
          // patientId:"erXuFYUfucBZaryVksYEcMg3",
          // organizationName:"Epic Integration",
          // launchToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ1cm46b2lkOjEuMi44NDAuMTE0MzUwLjEuMTMuMC4xLjcuMy42ODg4ODQuMTAwIiwiY2xpZW50X2lkIjoiMWZmYTQ0OTUtYzM0OS00MWI4LTk5Y2QtNzRmOTAyZjk3ZDQ5IiwiZXBpYy5lY2kiOiJ1cm46ZXBpYzpPcGVuLkVwaWMtY3VycmVudCIsImVwaWMubWV0YWRhdGEiOiJ1SlRzN28tN3JBRXdkWXp0NGFpanVPZG03emQzajZfaEsyWXB1YW0zdHVrZHQ2MVEwSGFROFFmRURrdjVqTkhuRjZCXzRualFFVkE5a2kxdHM3TGRFV3BxXzNlNFFreVRLUGJfMF9xZU5TNW9Damt4Y21XV0t4WFhSMjd0ZXpDZCIsImVwaWMudG9rZW50eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM0Mzg5NzQxLCJpYXQiOjE3MzQzODYxNDEsImlzcyI6InVybjpvaWQ6MS4yLjg0MC4xMTQzNTAuMS4xMy4wLjEuNy4zLjY4ODg4NC4xMDAiLCJqdGkiOiI2ZjdmNTQxZC03MGM0LTQwZWQtYjlhMi04ZmJkZjA1YmMxOTgiLCJuYmYiOjE3MzQzODYxNDEsInN1YiI6ImUzVjZFSEk2Y2pxMGpQeFZKN0dKT0htbzlqTzlvaElGdVFpcnF6VGdHMEFJMyJ9.VQDoNUQMbSpiqhmSOEGNdf75ulsziTvV-wE6eIZRNMAdYJYNlW0S6s9-1YoBQ6yFocd23DaxP1Dr927snZI9bMO5BUBJunodvt8MqaJJ6FSpAadDNiDwJCPi77UYKJ9np0o_qWRHWfscGw4VQejitlFuZhdQI9aj4-7GJkUfCl63TnOdhYT3obWMk1oew-w2sDO0kMwcnc9wHjruvRmIsyB-egGTpZWZMsSSHuUkZW43GEAn38SeIncR1VQiyKxBY47UOXHKsBjFZ-6YXF_cA_zqRPx2_PERjNIP4Y2Gi8ANTKUyo0fFY-1P0Ih4oPRLKQoKb7tTUlYbAVg9K_7G0A",
          patientId:ehrPatientId,
          organizationName:ehrOrgName,
          launchToken: ehrAccessToken 
        }),
      });
      const responseData =await response.json();
       const ehrPatientName =responseData.fullName;
      const ehrPatientDOB= responseData.birthDate;
      
      console.log("patient",responseData)
      if (response.ok) {
        router.push({ name: 'addEnconter', query: { ehrEncounterId, ehrDoctorName,ehrPatientName,ehrOrgName,ehrPatientId,ehrPatientDOB } });
      }
  }

</script>



